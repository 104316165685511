<template>
  <div class="LoginDiv">
    <el-form
      :model="ruleForm"
      status-icon
      ref="ruleForm"
      label-position="left"
      label-width="60px"
      class="demo-ruleForm"
    >
      <div class="titleImg">
        <el-image
          class="elImg"
          src="https://suezp.cn/server/kmProfile.png"
          fit="fill"></el-image>
      </div>
      <div class="titleName">
        可萌数据系统
      </div>
      <el-form-item label="用户名" prop="pass" class="loginInpt">
        <el-input
          type="user"
          v-model="ruleForm.username"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="checkPass" class="loginInpt">
        <el-input
          type="password"
          v-model="ruleForm.password"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <div type="primary" class="btn" @click="login" >
        <span>登入 </span>
        <van-loading v-if="btnLoading" style="display:inline-block;" size="16px" color="#ddd4d4" />
        </div>
      <div class="formFooter">
        <span style="color: #477066; margin-right: 40px">PowerBy Suezp </span>
        <span style="color: #ddd4d4;">Version: {{ version }}</span>
      </div>
    </el-form>
    <div class="footer">
      <span @click="goBeian">闽ICP备19006430号-2</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import MD5 from "md5-node";
export default {
  name: "KmLogin",
  components: {},
  data() {
    return {
      version: "1.1.3",
      ruleForm: {
        username: "",
        password: "",
      },
      btnLoading: false,
    };
  },
  mounted() {
    this.$message({
      showClose: true,
      message: "请先登入",
      type: "info",
    });
    this.btnLoading = false;
  },
  methods: {
    login() {
      this.btnLoading = true;
      console.log(MD5(this.ruleForm.password));
      let param = {
        username: this.ruleForm.username,
        password: MD5(this.ruleForm.password),
      };
      axios.post(this.$baseUrl + "/consumerList/kmLogin", param)
      .then((res) => {
        this.btnLoading = false;
        if (res.data.code == 200) {
          localStorage.setItem("token", res.data.token);
          axios.defaults.headers.Authorization = res.data.token;
          this.$router.push("/kmSystemCenter");
          this.$message({
            message: '登入成功',
            type: "success"
          });
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "warning",
          });
        }
      })
      .catch(e=>{
        console.log(e);
        this.btnLoading = false;
        this.$message({
            showClose: true,
            message: '登入失败，请询问开发人员',
            type: "warning",
          });
      });
    },
    autoLogin() {
      
    },
    goBeian() {
      window.open("http://www.beian.gov.cn/");
    },
  },
};
</script>
<style scoped lang="less">
.LoginDiv {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  margin: 0px auto;
  padding-top: 120px;
  background: url("https://suezp.cn/server/kmbj.png") no-repeat;
  background-size: cover;
  .titleImg {
    text-align: center;
    margin-bottom: 0px;
    box-sizing: border-box;
    .elImg {
      width: 70px;
      height: 70px;
      border-radius: 35px;
    }
  }
  .titleName {
    text-align: center;
    margin-bottom: 20px;
    letter-spacing: 1px;
    font-size: 15px;
    color:#ddd4d4;
  }
}
.demo-ruleForm {
  padding: 20px 40px 10px;
  min-height: 300px;
  width: 320px;
  margin: 0px auto;
  border-radius: 10px;
  box-shadow: -4px 3px 20px rgb(0 0 0 / 70%);
  border: 1px solid #00000042;
  transition: all 0.2s ease-in-out 0s;
  .loginInpt{
    /deep/.el-form-item__label {
        color: #ddd4d4;;
      }
  }
  .btn {
    line-height: 36px;
    text-align: center;
    color: #fff;
    font-size: 15px;
    border: 1px solid #fff;
    width: 30%;
    min-width: 120px;
    margin-top: 20px;
    border-radius: 20px;
    height: 36px;
    transform: translateX(-50%);
    margin-left: 50%;
    cursor: pointer;
    user-select: none;
  }
}
.demo-ruleForm:hover {
  box-shadow: -2px 1px 10px rgb(0 0 0 / 90%);
}
.formFooter {
  font-size: 14px;
  margin-top: 20px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
}
.footer {
  width: 100%;
  position: absolute;
  bottom: 10px;
  font-size: 12px;
  color: #fff;
  text-align: center;
  span {
    cursor: pointer;
  }
}
// H5 样式
@media screen and (max-width: 540px) {
  .titleName {
    text-align: center;
    margin-bottom: 30px;
    letter-spacing: 1px;
    font-size:20px;
    color:#ddd4d4;
  }
  .LoginDiv{
    padding-top: 80px;
  }
  .demo-ruleForm {
    width: 85%!important;
    box-sizing: border-box;
    padding: 20px 5px 20px;
    box-shadow: none;
    border: none;
    .loginInpt {
      background: rgba(0, 0, 0, 0.19);
      height: 40px;
      border-radius: 20px;
      box-sizing: border-box;
      padding-left: 20px;
      overflow: hidden;
      
      /deep/.el-form-item__label {
        color: #ddd4d4;;
      }
      /deep/.el-input__inner{
        background: none!important;
        border: none;
        color: #fff;
      }
    }
    .btn {
      line-height: 36px;
      text-align: center;
      color: #fff;
      font-size: 15px;
      border: 1px solid #fff;
      width: 40%;
      margin-top: 20px;
      border-radius: 20px;
      height: 36px;
      transform: translateX(-50%);
      margin-left: 50%;
    }
  }
  .demo-ruleForm:hover {
    box-shadow: none;
  }
}
</style>